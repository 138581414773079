import './FeedItem.css';
import AvatarIcon from '../assets/common/avatar.png';

const limitText=(text)=>{
  if(text.length > 50) return `${text.slice(0, 50)} ...`;
  return text;
}

const TimestampToUTC8=(timestamp)=>{
  const utc8Offset = 8 * 60 * 60 * 1000;
  const localTime = new Date(timestamp);
  const utc8Time = new Date(localTime.getTime() + utc8Offset);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'Asia/Shanghai', // 设置时区为UTC+8
  };
  return utc8Time.toLocaleString('en-US', options)
}


const FeedItem = (props) =>{
  const {itemId, author, avatar, title, published, source, onItemClick} = props;
  const handleItemClick = () =>{
    onItemClick(itemId)
  }
  return <>
    <div className='feed-item' onClick={handleItemClick}>
      <img src={avatar?avatar:AvatarIcon}/>
      <dl>
        <dt>{author}</dt>
        <dd>{limitText(title)}</dd>
        <dd>{TimestampToUTC8(parseInt(published))} <i className='bx bx-link'>{source}</i></dd>
      </dl>
    </div>
  </>
}

export default FeedItem;

import './PannelTrans.css';
import { useEffect, useState, useRef, forwardRef } from 'react';

const TransText = ({start, text, dur, onTransClick, currentSecond})=>{
  const [currentText, setCurrentText] = useState(false);

  useEffect(()=>{
    if(Number(start) <= Number(currentSecond) && Number(start) + Number(dur) >= Number(currentSecond)){
      setCurrentText(true)
    }else{
      setCurrentText(false);
    }
  }, [currentSecond])


  const convertToMMSS = (secondsString) => {
    let seconds = parseFloat(secondsString);
    seconds = seconds < 0?0:seconds;
    const milliseconds = seconds * 1000;
    const minutes = Math.floor(milliseconds / 60000);
    const remainingSeconds = Math.floor((milliseconds % 60000) / 1000);
    const formatted = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    return formatted;
  };
  const handleTransClick = () =>{
    onTransClick(Number(start))
  }
  return (
    <p className={`trans-text ${currentText?'highlight':''}`} onClick={handleTransClick} data-start={start} ><span className='trans-time'>{convertToMMSS(start)  }</span> <span className='trans-text'> {text} </span></p>
  )
}

const FeedPannelTrans = ({transItems, onTransClick, currentTime}) =>{
  const [currentSecond, setCurrentSecond]=useState(0);
  useEffect(()=>{
    setCurrentSecond(currentTime)
  },[currentTime])

  const handleTransClick = (seconds) =>{
    onTransClick(seconds)
    setCurrentSecond(seconds)
  }
  return (<>
  {
    transItems.map((transItem) => (
      <TransText key={transItem.start} start={transItem.start} text={transItem.text} dur={transItem.dur} onTransClick={handleTransClick} currentSecond={currentSecond}/>
    ))
  }
  </>)
}
export default FeedPannelTrans;
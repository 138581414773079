import './NavbarSide.css';
import { useState } from 'react';
// import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const NavbarSide = (props) =>{
  const { navItems } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [currItem, setCurrItem] = useState('');
  const navigate = useNavigate();

  const handleClick = () =>{
    const newMode = isOpen ? false : true;
    setIsOpen(newMode);
  }
  const handleChoice = (itemName, link) => () =>{
    setCurrItem(itemName);
    navigate(link);
  }

  return (
    <div className={isOpen?'navbar-side':'navbar-side close'}>
      <ul>
        <li className='navbar-side-item' onClick={handleClick}>VEX</li>
        {navItems.map((navItem, i)=>(
          <li className={currItem===navItem.name?'navbar-side-item choice':'navbar-side-item'} 
            key={i} onClick={handleChoice(navItem.name, navItem.link)} >
              <i className='bx bxs-ghost' ></i>
              <span>{navItem.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}


export default NavbarSide;
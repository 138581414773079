import React, { useEffect, useState, useRef, useCallback } from 'react';
import './MediaCard.css';

const MediaCardControls = ({startTime, endTime, showControls}) =>{
  function secondsToMinutesAndSeconds(seconds) {
    // 将秒数转换为分钟和秒数
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    // 确保分钟和秒数始终以两位数格式显示
    minutes = String(minutes).padStart(2, '0');
    remainingSeconds = String(remainingSeconds).padStart(2, '0');

    // 返回格式化后的字符串
    return `${minutes}:${remainingSeconds}`;
}
  return (
    <>
     {showControls?(
        <div className='smedia-card-controls'>
          <button className='smedia-starttime-button' >
            {endTime - startTime}
          </button>
          <button className='smedia-duration-button' >
            {startTime}
            {/* {secondsToMinutesAndSeconds(startTime)} */}
          </button>
        </div>
      ):(<></>)
    }
    </>
  )

}




const MediaCard = ({itemId, mediaLink, startTime, endTime, onVideoClick, onLoadData}) => {
  const videoRef = useRef(null);
  const startTimeRef = useRef(0);
  const endTimeRef = useRef(0);

  useEffect(()=>{
    const videoElement = videoRef.current;
    if(!videoElement) return;
    const handleTimeUpdate = () => {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      if (videoElement.readyState < 3) return;
      if (videoElement.currentTime >= videoElement.duration) videoElement.currentTime = startTimeRef.current;
      if (videoElement.currentTime > endTimeRef.current) videoElement.currentTime = startTimeRef.current;
    };

    const handleLoadData = () => {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      onLoadData(videoRef);
    };
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    videoElement.addEventListener('loaddata', handleLoadData);
    videoElement.currentTime = startTimeRef.current;
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      videoElement.removeEventListener('loaddata', handleLoadData);
    };
  },[]);

  useEffect(()=>{
    startTimeRef.current = startTime;
    endTimeRef.current = endTime;
  },[startTime, endTime]);

  useEffect(()=>{
    const videoElement = videoRef.current;
    if(!videoElement) return;
    videoElement.src = mediaLink;
    videoElement.controls=false;
    videoElement.currentTime = startTimeRef.current;
    videoElement.load();
  },[mediaLink])

  const handleVideoClick = () =>{
    const videoElement = videoRef.current;
    if(!videoElement) return;
    if(startTimeRef.current > videoElement.duration) return;
    if(videoElement.paused){
      videoElement.currentTime = startTimeRef.current;
      videoElement.play();
      return
    }
    const currentTime = videoElement?.currentTime || startTimeRef.current;
    onVideoClick(itemId, currentTime);
  }

  return (
    <div className='smedia-card-container' onClick={handleVideoClick}>
      <video className='smedia-card' ref={videoRef} controls playsInline muted={true} preload={"metadata"}></video>
      <MediaCardControls startTime={startTime} endTime={endTime} showControls={true}/>
    </div>
  );
}

const MediaCardBak = ({itemId, mediaLink, startTime, endTime, onVideoClick, onLoadData}) => {
  if(!mediaLink || mediaLink === '') return null;
  const videoRef = useRef(null);
  const startTimeRef = useRef(startTime);
  const endTimeRef = useRef(endTime);

  useEffect(()=>{
    startTimeRef.current = startTime;
    endTimeRef.current = endTime;
  },[startTime, endTime])

  useEffect(()=>{
    const videoElement = videoRef.current;
    if(!videoElement) return;
    if(!mediaLink || mediaLink === '') return;
    videoElement.src = mediaLink;
    videoElement.controls=false;
    videoElement.currentTime = startTimeRef.current;
    videoElement.load();

    const handleTimeUpdate = () => {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      if (videoElement.readyState < 3) return;
      if (videoElement.currentTime >= videoElement.duration) videoElement.currentTime = startTimeRef.current;
      if (videoElement.currentTime > endTimeRef.current) videoElement.currentTime = startTimeRef.current;
    };

    const handleLoadData = () => {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const duration = videoElement.duration;
      onLoadData(videoRef);
    };
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    videoElement.addEventListener('loaddata', handleLoadData);
    videoElement.currentTime = startTimeRef.current;
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      videoElement.removeEventListener('loaddata', handleLoadData);
    };
  },[mediaLink]);

  const handleVideoClick = () =>{
    const videoElement = videoRef.current;
    if(!videoElement) return;
    if(startTimeRef.current > videoElement.duration) return;
    if(videoElement.paused){
      videoElement.currentTime = startTimeRef.current;
      videoElement.play();
      return
    }
    const currentTime = videoElement?.currentTime || startTimeRef.current;
    onVideoClick(itemId, currentTime);
  }

  return (
    <div className='smedia-card-container' onClick={handleVideoClick}>
      <video className='smedia-card' ref={videoRef} controls playsInline muted={true}></video>
      <MediaCardControls startTime={startTime} endTime={endTime} showControls={true}/>
    </div>
  );
}
export default MediaCard;


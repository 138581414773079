import './MediaPage.css';
import { useState, useEffect, useRef } from "react"
import {Channels, GetFeed, Timeline, Following, SubChannel, BanFeed} from '../api/feed';
import ScrollableList from '../components/ScrollableList';
import FeedItem from '../components/FeedItem';
import PannelContent from '../components/PannelContent';
import PannelTrans from '../components/PannelTrans';
import MediaPlayer from '../components/MediaPlayer';
import PannelPreviews from '../components/PannelPreviews';

const MediaList = ({onItemSelect}) =>{
  const [feedItems, setFeedItems] = useState([]);
  const moreItems = async () =>{
    const nowTs = new Date().getTime();
    const response = await Timeline('MEDIA', nowTs);
    if(response.code !== 200) return [];
    const newItems = response.items.filter((item)=>{
      return !feedItems.find((feedItem)=>{return feedItem.feed_id === item.feed_id})
    });
    setFeedItems((prevItems) => [...prevItems, ...newItems]);
  }
  useEffect(()=>{
    moreItems();
  },[]);

  const onScrollUp = async () =>{ await moreItems();}
  const onItemClick = (itemId) =>{ onItemSelect(itemId);}
  return (<>
    <ScrollableList onScrollUp={onScrollUp} >
      {feedItems.map((feed, i)=>{
        return (
          <FeedItem key={feed.feed_id} 
          itemId={feed.feed_id}
          author={feed.channel_source_name} 
          avatar={feed.channel_source_icon} 
          title={feed.title} 
          published={feed.published_ts} 
          source={feed.feed_source}
          onItemClick={onItemClick} 
          />
        )
      })}
    </ScrollableList>
  </>)
}

const MediaPannel = ({feedItemId, onItemSelect}) =>{
  const [feedItem, setFeedItem] = useState(undefined);
  const [pannelState, setPannelState] = useState('CONTENT');
  const [content, setContent] = useState('');
  const [transcript, setTranscript] = useState([]);
  const [previewItems, setPreviewItems] = useState([]);
  const videoRef = useRef(undefined);



  const getFeedItem = async(itemId) =>{
    const response = await GetFeed(itemId);
    if(response.code !== 200) return;
    const theItem = response.feed;
    if(!theItem) return;
    setFeedItem(theItem);
    setContent(theItem.content);
    setTranscript(theItem.transcript);
  }

  useEffect(()=>{
    setFeedItem(undefined);
    setTranscript([]);
    setPreviewItems([]);
    getFeedItem(feedItemId);
  },[feedItemId]);

  const onMediaLoad = (videoElement) =>{
    console.log('onMediaLoad');
    videoRef.current = videoElement;
  }
  const secondsFromPriview = (seconds) =>{
    console.log('secondsFromPriview', seconds)
    if(!videoRef.current) return;
    videoRef.current.currentTime = seconds
  }
  const secondsFromTrans = (seconds) =>{
    console.log('secondsFromTrans', seconds)
  }
  const secondsFromMediaPlayer = (seconds) =>{
    // console.log('secondsFromMediaPlayer', seconds)
    // setMediaSecond(seconds);
  }

  const onGoLinkClick = () =>{ if(feedItem) window.open(feedItem.feed_source_link, '_blank');}
  const onGoBackClick = () =>{ 
    setFeedItem(undefined);
    onItemSelect(undefined); 
  }
  const onContentClick = () =>{
    setPreviewItems([]);
    setPannelState('CONTENT')
  }
  const onTransClick = () =>{
    setPreviewItems([]);
    setPannelState('TRANS')
  }
  const onPreviewClick = () =>{
    const previews = feedItem.scenes.map((shot)=>{
      return {
        media: feedItem.compress_link,
        start: shot.start,
        end: shot.end,
        dur: shot.dur,
      }
    })
    setPreviewItems([...previews]);
    setPannelState('PREVIEW')
  }

  const getMediaLink = () =>{
    if(!feedItem) return '';
    const isMobile = window.innerWidth <= 768;
    if(isMobile) return feedItem.compress_link;
    return feedItem.media_link
  }
  return (<>
    <div className='pannel-body'>
      <div className='pannel-title'>
        {feedItem?feedItem.title:''}
      </div>
      <div className='pannel-media'>
        <MediaPlayer mediaLink={getMediaLink()} onLoadData={onMediaLoad} onTimeUpdate={secondsFromMediaPlayer}/>
        {/* <MediaPlayer mediaLink={feedItem?feedItem.media_compress:''} onLoadData={onMediaLoad}/> */}
      </div>
      <div className='pannel-content'>
        <PannelPreviews previews={previewItems} onSecondSelect={secondsFromPriview}/>
        { pannelState === 'CONTENT'?(<PannelContent feedContent={content}/>):(<></>)}
        { pannelState === 'TRANS'?(<PannelTrans transItems={transcript} onTransClick={secondsFromTrans}/>):(<></>)}
      </div>
      <div className='pannel-buttons'>
        <button onClick={onGoLinkClick}>Link</button>
        <button onClick={onContentClick}>Content</button>
        <button onClick={onPreviewClick} >Preview</button>
        <button>Prev</button>
        <button onClick={onGoBackClick}>Back</button>
        <button onClick={onTransClick}>Transcript</button>
        <button>Ban</button>
        <button>Next</button>
      </div>
    </div>
  </>)
}



const MeidaPage = () =>{
  const [feedItemId, setFeedItemId] = useState(undefined);

  const itemSelect = (itemId) =>{
    console.log('itemSelect');
    setFeedItemId(itemId);
  }

  return (<>
    <div className='media-page'>
      <div className='media-page-list'>
        <MediaList onItemSelect={itemSelect} />
      </div>
      <div className={feedItemId?'media-page-pannel active':'media-page-pannel'}>
        <MediaPannel feedItemId={feedItemId} onItemSelect={itemSelect}/>
      </div>
    </div>
  </>)
}




export default MeidaPage;
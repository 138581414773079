import './PannelPreviews.css';
import React, { useEffect, useState, useRef } from 'react';
import MediaCard from './MediaCard';

const PannelPreviews = ({previews, onSecondSelect}) =>{
  const [shotItems, setShotItems] = useState([]);
  useEffect(()=>{
    setShotItems([...previews])
  },[previews]);

  const onVideoClick = (itemId, seconds)=>{
    onSecondSelect(seconds);
  }
  const onLoadData = ()=>{}

  return (
    <div className='pannel-previews-contianer'>
      {shotItems.map((item) => (
        <div key={item.start} className='pannel-previews-item'>
          <MediaCard 
          key={item.start} 
          itemId={item.start} 
          mediaLink={item.media} 
          startTime={item.start} 
          endTime={item.end} 
          onVideoClick={onVideoClick} 
          onLoadData={onLoadData}/>
        </div>
      ))}
    </div>
  )
}

export default PannelPreviews;
import './FeedPage.css'
import { useState, useEffect,useRef } from "react"
import ScrollableList from '../components/ScrollableList';
import FeedItem from '../components/FeedItem';
import PannelContent from '../components/PannelContent';
import PannelTrans from '../components/PannelTrans';
import MediaPlayer from '../components/MediaPlayer';
import {Channels, GetFeed, Timeline, Following, SubChannel, BanFeed} from '../api/feed';



const FeedList = ({onItemSelect}) =>{
  const [feedItems, setFeedItems] = useState([]);
  const moreItems = async () =>{
    const nowTs = new Date().getTime();
    const response = await Timeline('YOUTUBE', nowTs);
    if(response.code !== 200) return [];
    const newItems = response.items.filter((item)=>{
      return !feedItems.find((feedItem)=>{return feedItem.feed_id === item.feed_id})
    });
    setFeedItems((prevItems) => [...prevItems, ...newItems]);
  }
  useEffect(()=>{
    moreItems();
  },[]);

  const onScrollUp = async () =>{ await moreItems();}
  const onItemClick = (itemId) =>{ onItemSelect(itemId);}
  return (<>
    <ScrollableList onScrollUp={onScrollUp} >
      {feedItems.map((feed, i)=>{
        return (
          <FeedItem key={feed.feed_id} 
          itemId={feed.feed_id}
          author={feed.channel_source_name} 
          avatar={feed.channel_source_icon} 
          title={feed.title} 
          published={feed.published_ts} 
          source={feed.feed_source}
          onItemClick={onItemClick} 
          />
        )
      })}
    </ScrollableList>
  </>)
}

const FeedPannel = ({feedItemId, onItemSelect}) =>{
  const [feedItem, setFeedItem] = useState(undefined);
  const [pannelState, setPannelState] = useState('CONTENT');
  const [content, setContent] = useState('');
  const [transcript, setTranscrip] = useState([]);
  const [mediaSecond, setMediaSecond] = useState(0);
  const videoRef = useRef(undefined);


  const getFeedItem = async(itemId) =>{
    const nowTs = new Date().getTime();
    const response = await GetFeed(itemId);
    if(response.code !== 200) return;
    const theItem = response.feed;
    if(!theItem) return;
    setFeedItem(theItem);
    setContent(theItem.content);
    setTranscrip(theItem.transcript);
  }

  useEffect(()=>{
    getFeedItem(feedItemId);
  },[feedItemId]);

  const onVideoLoad=(videoElement)=>{
    if(!videoElement) return;
    videoRef.current = videoElement;
  }
  const secondsFromTrans = (seconds) =>{
    console.log('secondsFromTrans', seconds)
  }
  const secondsFromMediaPlayer = (seconds) =>{
    // console.log('secondsFromMediaPlayer', seconds)
    setMediaSecond(seconds);
  }

  const onGoLinkClick = () =>{ if(feedItem) window.open(feedItem.feed_source_link, '_blank');}
  const onGoBackClick = () =>{ 
    setFeedItem(undefined);
    onItemSelect(undefined); 
  }
  const onContentClick = () =>{setPannelState('CONTENT')}
  const onTransClick = () =>{
    setPannelState('TRANS')
    let theTime = 0;
    for(const trans of transcript){
      if(Number(trans.start) > mediaSecond) break;
      theTime = Number(trans.start)
    }
    const currentTransTextElement = document.querySelector(`[data-start="${theTime}"]`);
    if (currentTransTextElement) {
      currentTransTextElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }
  }
  return (<>
    <div className='pannel-body'>
      <div className='pannel-title'>
        {feedItem?feedItem.title:''}
      </div>
      <div className='pannel-media'>
        <MediaPlayer mediaLink={feedItem?feedItem.media_link:''} onLoadData={onVideoLoad} onTimeUpdate={secondsFromMediaPlayer}/>
      </div>
      <div className='pannel-content'>
        { pannelState === 'CONTENT'?(<PannelContent feedContent={content}/>):(<></>)}
        { pannelState === 'TRANS'?(<PannelTrans transItems={transcript} onTransClick={secondsFromTrans} currentTime={mediaSecond}/>):(<></>)}
      </div>
      <div className='pannel-buttons'>
        <button onClick={onGoLinkClick}>Link</button>
        <button onClick={onContentClick}>Content</button>
        <button>Sub</button>
        <button>Prev</button>
        <button onClick={onGoBackClick}>Back</button>
        <button onClick={onTransClick}>Transcript</button>
        <button>Ban</button>
        <button>Next</button>
      </div>
    </div>
  </>)
}


const FeedPage = () =>{
  const [feedItemId, setFeedItemId] = useState(undefined);

  const itemSelect = (itemId) =>{
    console.log('itemSelect');
    setFeedItemId(itemId);
  }

  return (<>
    <div className='feed-page'>
      <div className='feed-page-list'>
        <FeedList onItemSelect={itemSelect} />
      </div>
      <div className={feedItemId?'feed-page-pannel active':'feed-page-pannel'}>
        <FeedPannel feedItemId={feedItemId} onItemSelect={itemSelect}/>
      </div>
    </div>
  </>)
}




export default FeedPage;
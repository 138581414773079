import './MediaPlayer.css';
import React, { useEffect, useState, useRef } from 'react';




const CMediaControls=({progress, currTime, duration, showControls, playing, muted, onProgressChange, onControlClick, onPlayPauseClick, onMuteClick, onForwardClick, onRwindClick})=>{
  const progressRef = useRef(null);
  const handleProgressBarChange = (e) => {
    console.log('handleProgressBarChange')
    onProgressChange(e);
  };

  const handlePlayPauseButtonClick = () =>{
    onPlayPauseClick();
    console.log('handlePlayPauseButtonClick')
  }

  const handleVolumeToggleClick = () =>{
    onMuteClick();
  }

  const handleTimelineClick = () =>{
    console.log('handleTimelineClick')
    onControlClick()
  }

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleForwardClick = () =>{
    onForwardClick()
  }

  const handleRwindClick = ()=>{
    onRwindClick();
  }

  return (
    <>
     {showControls?(
        <div className='cmedia-controls'>
          <input className='cmedia-progress-bar' ref={progressRef} type='range'  value={progress} max='100' onChange={handleProgressBarChange}/>
          {currTime?(
            <div className='cmedia-time-display' onClick={handleTimelineClick}>
            {formatTime(currTime)}/{formatTime(duration)}
            </div>
          ):(<></>)}
          <button className='cmedia-play-pause-button' onClick={handlePlayPauseButtonClick}>
            {!playing?(<i className='bx bx-play-circle' ></i>):(<i className='bx bx-pause-circle' ></i>)}
          </button>
          <button className='cmedia-volume-toggle-button' onClick={handleVolumeToggleClick}>
            {muted?(<i className='bx bx-volume-full' ></i>):(<i className='bx bx-volume-mute' ></i>)}
          </button>
          <button
            className='cmedia-transparent-button cmedia-forward-button'
            onClick={handleForwardClick}
          ></button>
          <button
            className='cmedia-transparent-button cmedia-rwind-button'
            onClick={handleRwindClick}
          ></button>
        </div>
      ):(<></>)
    }
    </>
  )
}
const MediaPlayer = ({mediaLink, onLoadData, onTimeUpdate}) => {
  const videoRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [showControls, setShowControls] = useState(false);

  useEffect(()=>{
    const videoElement = videoRef.current;
    if(!videoElement) return;
    setProgress(0)
    const handleLoadedMetadata = () =>{
      // console.log('handleLoadedMetadata')
      onLoadData(videoElement);
    }
    const handleTimeUpdate = () => {
      updateProgress()
      onTimeUpdate(videoElement.currentTime)
      // console.log('handleTimeUpdate',videoElement.currentTime) 
    };
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  },[]);

  useEffect(()=>{
    const videoElement = videoRef.current;
    if(!videoElement) return;
    videoElement.src = mediaLink;
    setProgress(0);
  },[mediaLink]);



  const updateProgress = () =>{
    if(!videoRef.current) return;
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressValue = (currentTime / duration) * 100;
    setProgress(progressValue);
  }

  const handleProgress = (e) =>{
    if(!videoRef.current) return;
    const newValue = e.target.value;
    const duration = videoRef.current.duration;
    const newTime = (newValue / 100) * duration;
    videoRef.current.currentTime = newTime;
    setProgress(newValue);
  }

  const handleControlHidden= () =>{
    console.log('handleControlHidden')
    setShowControls(false)
  }

  const handleControlShow = ()=>{
    console.log('handleControlShow')
    if(showControls === true) return;
    setShowControls(true)
  }

  const handlePlayPauseClick = () =>{
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }

  const handleMuteClick =()=>{
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  }

  const handleForwardClick=()=>{
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current.currentTime + 5;
    }
  }

  const handleRwindClick=()=>{
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current.currentTime - 5;
    }
  }


  return (
    <div className='cmedia-player-container' onClick={handleControlShow}>
      <video ref={videoRef} className='cmedia-player' autoPlay playsInline muted ></video>
      <CMediaControls 
      progress={progress} currTime={videoRef.current?.currentTime} duration={ videoRef.current?.duration}
      showControls={showControls} playing={!videoRef.current?.paused} muted={videoRef.current?.muted}
      onProgressChange={handleProgress} onControlClick={handleControlHidden} 
      onPlayPauseClick={handlePlayPauseClick} onMuteClick={handleMuteClick}
      onForwardClick={handleForwardClick} onRwindClick={handleRwindClick}
      />
    </div>
  );
};

export default MediaPlayer;
import './MediaPage.css';
import { useState, useEffect, useRef } from "react"
import {Channels, GetFeed, Timeline, Following, SubChannel, BanFeed} from '../api/feed';
import ScrollableList from '../components/ScrollableList';
import FeedItem from '../components/FeedItem';
import PannelContent from '../components/PannelContent';
import PannelTrans from '../components/PannelTrans';
import MediaPlayer from '../components/MediaPlayer';
import PannelPreviews from '../components/PannelPreviews';



const MediaPannel = ({feedItemId, onItemSelect}) =>{
  const [feedItem, setFeedItem] = useState(undefined);
  const [pannelState, setPannelState] = useState('CONTENT');
  const [content, setContent] = useState('');
  const [transcript, setTranscript] = useState([]);
  const [previewItems, setPreviewItems] = useState([]);
  const [mediaSecond, setMediaSecond] = useState(0);
  const videoRef = useRef(undefined);

  const getFeedItem = async(itemId) =>{
    // const response = await GetFeed(itemId);
    // if(response.code !== 200) return;
    const response = await fetch('/feed.json');
    console.log(response);
    const theItem = await response.json();
    // const theItem = response.feed;
    if(!theItem) return;
    setFeedItem(theItem);
    setContent(theItem.content);
    setTranscript(theItem.transcript);
  }

  useEffect(()=>{
    setFeedItem(undefined);
    setTranscript([]);
    setPreviewItems([]);
    getFeedItem(feedItemId);
  },[feedItemId]);

  const onMediaLoad = (videoElement) =>{
    console.log('onMediaLoad');
    videoRef.current = videoElement;
  }
  const secondsFromPriview = (seconds) =>{
    console.log('secondsFromPriview', seconds)
    if(!videoRef.current) return;
    videoRef.current.currentTime = seconds

  }
  const secondsFromTrans = (seconds) =>{
    console.log('secondsFromTrans', seconds)
  }
  const secondsFromMediaPlayer = (seconds) =>{
    // console.log('secondsFromMediaPlayer', seconds)
  }

  const onGoLinkClick = () =>{ if(feedItem) window.open(feedItem.feed_source_link, '_blank');}
  const onGoBackClick = () =>{ 
    setFeedItem(undefined);
    onItemSelect(undefined); 
  }
  const onContentClick = () =>{
    setPreviewItems([]);
    setPannelState('CONTENT')
  }
  const onTransClick = () =>{
    setPreviewItems([]);
    setPannelState('TRANS')
  }
  const onPreviewClick = () =>{
    const previews = feedItem.shots.map((shot)=>{
      return {
        media: feedItem.media_compress,
        start: shot.start,
        end: shot.end,
        dur: shot.dur,
      }
    })
    setPreviewItems([...previews]);
    setPannelState('PREVIEW')
  }
  return (<>
    <div className='pannel-body'>
      <div className='pannel-title'>
        {feedItem?feedItem.title:''}
      </div>
      <div className='pannel-media'>
        <MediaPlayer mediaLink={feedItem?feedItem.media:''} onLoadData={onMediaLoad} onTimeUpdate={secondsFromMediaPlayer}/>
      </div>
      <div className='pannel-content'>
        <PannelPreviews previews={previewItems} onSecondSelect={secondsFromPriview}/>
        { pannelState === 'CONTENT'?(<PannelContent feedContent={content}/>):(<></>)}
        { pannelState === 'TRANS'?(<PannelTrans transItems={transcript} onTransClick={secondsFromTrans}/>):(<></>)}
      </div>
      <div className='pannel-buttons'>
        <button onClick={onGoLinkClick}>Link</button>
        <button onClick={onContentClick}>Content</button>
        <button onClick={onPreviewClick} >Preview</button>
        <button>Prev</button>
        <button onClick={onGoBackClick}>Back</button>
        <button onClick={onTransClick}>Transcript</button>
        <button>Ban</button>
        <button>Next</button>
      </div>
    </div>
  </>)
}

const DevPannel = () =>{
  const [videoURL, setVideoURL] = useState('');
  const [previewItems, setPreviewItems] = useState([]);
  const videoRef = useRef(undefined);
  const onMediaLoad = (videoElement) =>{
    // console.log('onMediaLoad');
    videoRef.current = videoElement;
  }
  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file);
    setVideoURL(objectURL);
  };
  const handlePreviewsChange = (event) =>{
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = JSON.parse(event.target.result);
      const items = data.map((item)=>{
        return {
          media: videoURL,
          start: item.start,
          end: item.end,
          dur: item.dur,
        }
      })
      setPreviewItems([...items]);
    };
    reader.readAsText(file);
  }
  const onVideoClick = () =>{
    document.getElementById('videoInput').click();
  }
  const onPreviewsClick = () =>{
    document.getElementById('previewInput').click();
  }
  const onBackClick = () =>{
    setVideoURL('');
  }
  const secondsFromPriview = (seconds) =>{
    console.log('secondsFromPriview', seconds)
    if(!videoRef.current) return;
    videoRef.current.currentTime = seconds
  }
  const secondsFromMediaPlayer = (seconds) =>{
    // console.log('secondsFromMediaPlayer', seconds)
    // setMediaSecond(seconds);
  }

  return (<>
    <div className='pannel-body'>
      <div className='pannel-title'></div>
      <div className='pannel-media'>
        <MediaPlayer mediaLink={videoURL} onLoadData={onMediaLoad} onTimeUpdate={secondsFromMediaPlayer}/>
      </div>
      <div className='pannel-content'>
        <PannelPreviews previews={previewItems} onSecondSelect={secondsFromPriview}/>
      </div>
      <div className='pannel-buttons'>
        <button onClick={onVideoClick}>Video</button>
        <button >Content</button>
        <button onClick={onPreviewsClick}>Preview</button>
        <button >Prev</button>
        <button onClick={onBackClick}>Back</button>
        <button >Transcript</button>
        <button>Ban</button>
        <button>Next</button>
      </div>
      <input id="videoInput" type="file" accept="video/*" style={{ display: 'none' }} onChange={handleVideoChange}/>
      <input id="previewInput" type="file" accept=".json" style={{ display: 'none' }} onChange={handlePreviewsChange}/>
    </div>
  </>)
}

const DevPage = () =>{
  const [feedItemId, setFeedItemId] = useState('1');

  const itemSelect = (itemId) =>{
    console.log('itemSelect');
    setFeedItemId(itemId);
  }

  return (<>
    <div className='media-page'>
      {/* <div className='media-page-list'>
        <MediaList onItemSelect={itemSelect} />
      </div> */}
      <div className='media-page-pannel active'>
        <DevPannel/>
      </div>
    </div>
  </>)
}

export default DevPage;
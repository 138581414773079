import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { navigationLinks } from './common/content';
import NavbarSide from './context/NavbarSide';
import TikTokPage from './pages/TikTokPage';
import FeedPage from './pages/FeedPage';
import MediaPage from './pages/MediaPage';
import DevPage from './pages/DevPage';
// import OfficialPage from './pages/OfficialPage';
const App = () =>{
  return (
    <>
    {/* <OfficialPage /> */}
      <BrowserRouter>
        <div className='main-container'>
        <NavbarSide navItems={navigationLinks}/>
          <Routes>
          <Route exact path="/dev" element={<DevPage/>}/>
          <Route exact path="/feed" element={<FeedPage/>}/>
          <Route exact path="/media" element={<MediaPage/>}/>
          <Route exact path="/tiktok" element={<TikTokPage/>}/>
          </Routes>
        </div>
      </BrowserRouter>
    </>

  )
}
export default App;
import './FeedMPannel.css';
import MVideoPlayer from './MVideoPlayer';
import React, { useEffect, useState, useRef } from 'react';

const FeedMPannel = (props) =>{
  const {feedItem, onFeedSwitch, onClickFeed, onSubClick, onBanClick } = props;
  const videoRefFromChild = useRef();
  const [rStart, SetRStart] = useState(0);

  const handleVideoRef = (ref) => {
    videoRefFromChild.current = ref;
  };

  useEffect(()=>{
    SetRStart(0);
  },[feedItem])

  const onRollBackClick = () =>{
    let current = videoRefFromChild.current.current.currentTime;
    current = current - 3;
    if(current > rStart && rStart !== 0) current = rStart;
    videoRefFromChild.current.current.currentTime = current;
  }

  const onRStartClick = () =>{
    const current = videoRefFromChild.current.current.currentTime;
    SetRStart(current)
  }

  const handlerSwitchNext = () =>{
    if(!feedItem) return;
    return onFeedSwitch(feedItem.feed_id, 1);
  }
  const handlerSwitchPrev = () =>{
    if(!feedItem) return;
    return onFeedSwitch(feedItem.feed_id, 0);
  }
  const handlerGoBack = () =>{
    onClickFeed(undefined);
  }
  const handlerGoLink = () =>{
    if(!feedItem) return;
    let link = feedItem.feed_source_link;
    if(feedItem.feed_source === 'TIKTOK') link = `https://www.tiktok.com/@${feedItem.channel_source_uid}/video/${feedItem.feed_source_id}`;
    if(feedItem.feed_source === 'TWITTER') link = `https://www.twitter.com/${feedItem.channel_source_uid}/status/${feedItem.feed_source_id}`;
    window.open(link, '_blank');
  }
  const handleSubscribe = async () =>{
    await onSubClick(feedItem.feed_id);
  }

  const handleBanClick = async () =>{
    await onBanClick(feedItem.feed_id);
  }

  return (
    <>
    <div className='mpannel-body'>
      <div className='mpannel-media'>
        <MVideoPlayer mediaLink={feedItem?feedItem.media:''} onVideoRef={handleVideoRef} rStart={rStart}/>
      </div>
      <div className='mpannel-buttons'>
        <button onClick={handlerGoLink}>Link</button>
        <button onClick={handleSubscribe}>Sub</button>
        <button onClick={onRStartClick}>RStart</button>
        <button onClick={handlerSwitchPrev}>Prev</button>

        
        <button onClick={handlerGoBack}>Back</button>
        <button onClick={handleBanClick}>Ban</button>
        <button onClick={onRollBackClick}>Roll</button>
        <button onClick={handlerSwitchNext}>Next</button>
      </div>
    </div>
    </>
  );
}


export default FeedMPannel;
import './PannelContent.css';
import { useEffect, useState, useRef } from 'react';



const FeedPannelContent = ({feedContent}) =>{
  const [content, setContent] = useState('');
  useEffect(()=>{
    if(feedContent){
      setContent(feedContent);
    } 
  },[feedContent]);
  return (<p>{content}</p>)
}
export default FeedPannelContent;
import './TikTokPage.css'
import React, { useEffect, useRef, useState } from 'react';
import FeedItem from '../components/FeedItem';
import ScrollableList from '../components/ScrollableList';
import FeedMPannel from '../components/FeedMPannel';
import {Timeline, Channels, Following, SubChannel, BanFeed} from '../api/feed';


const TikTokList = (props) =>{
  const { feedItems, onScrollUp, onClickFeed} = props;
  return (
    <div className='youtube-page-list'>
      <ScrollableList onScrollUp={onScrollUp} >
        {feedItems.map((feed, i)=>{
          return (
            <FeedItem key={feed.feed_id} 
            itemId={feed.feed_id}
            author={feed.channel_source_name} 
            avatar={feed.channel_source_icon} 
            title={feed.title} 
            published={feed.published_ts} 
            source={feed.feed_source}
            onItemClick={onClickFeed} 
            />
          )
        })}
      </ScrollableList>
    </div>
  )
}

const TikTokPannel = (props) =>{
  const {feedItem, onClickFeed} = props;
  const [subFeedItems, setSubFeedItems] = useState([]);
  const [currItem, setCurrItem] = useState(undefined);
  useEffect(()=>{
    if(!feedItem) {
      setCurrItem(undefined)
      setSubFeedItems([]);
      return;
    };
    setCurrItem({...feedItem})
    setSubFeedItems([feedItem])
  },[feedItem]);

  const getSubFeeds = async(channelUID, timestamp) =>{
    const response = await Following(channelUID, timestamp); 
    if(response.code !== 200) return [];
    const newItems = response.items.filter((item)=>{
      return !subFeedItems.find((feedItem)=>{return feedItem.feed_id === item.feed_id})
    });
    return newItems;
  }

  const handleMore = async () =>{
    if(subFeedItems.length === 0) [];
    const lastItem = subFeedItems[subFeedItems.length - 1];
    const channelUID = lastItem.channel_uid;
    const timestamp = lastItem.published_ts;
    const newItems = await getSubFeeds(channelUID, timestamp);
    setSubFeedItems((prevItems) => [...prevItems, ...newItems]);
    return newItems;
  }

  const handlerFeedSwitch = async (itemId, isNext) =>{
    const items = subFeedItems;
    const currIdx = subFeedItems.findIndex((item=>item.feed_id === itemId));
    if(currIdx === -1)return;
    if(currIdx > items.length - 2 && isNext) handleMore()
    const newFeedIdx = isNext?currIdx + 1:currIdx - 1;
    if(newFeedIdx > items.length - 1) return;
    if(newFeedIdx < 0) return;
    const newFeedItem = items[newFeedIdx];
    setCurrItem({...newFeedItem});
  }

  const handleSub = async (itemId) =>{
    console.log('handleSub', itemId)
    const feed = subFeedItems.find((item)=>{return item.feed_id===itemId});
    if(!feed) return;
    const sourceType = feed.feed_source;
    const sourceUid = feed.channel_source_uid;
    const sourceId = feed.channel_source_id;
    await SubChannel(sourceType, sourceUid, sourceId)
  }

  const handleBan = async (itemId) =>{
    console.log('handleBan', itemId)
    await BanFeed(itemId);
  }

  return (
    <>
    <div className={currItem?'youtube-page-pannel active':'youtube-page-pannel'}>
      <FeedMPannel feedItem={currItem} onFeedSwitch={handlerFeedSwitch} onClickFeed={onClickFeed} onSubClick={handleSub} onBanClick={handleBan}/>
    </div>
    </>
  )
}

const TikTokPage = () =>{
  const [feedItems, setFeedItems] = useState([]);
  const [feedItem, setFeetItem] = useState(undefined);
  useEffect(()=>{
    handlerScorllDown();
  },[]);

  const getFeeds = async(getNews) =>{
    const response = await Channels('TIKTOK');
    if(response.code !== 200) return [];
    const newItems = response.items.filter((item)=>{
      return !feedItems.find((feedItem)=>{return feedItem.feed_id === item.feed_id})
    });
    return newItems;
  }

  const handlerScorllDown = async() => {
    const newItems = await getFeeds(1);
    setFeedItems((prevItems) => [...newItems, ...prevItems]);
  }

  const handlerScorllUp = async() => {
    // const newItems = await getFeeds(0);
    // setFeedItems((prevItems) => [...prevItems, ...newItems]);
  }

  const handleItemClick = (itemId) =>{
    console.log('handleItemClick')
    const feed = feedItems.find((item)=>item.feed_id===itemId);
    if(!feed) {
      setFeetItem(undefined);
    }else{
      setFeetItem({...feed});
    }
  }

  return (<>
      <TikTokList feedItems={feedItems} onScrollUp={handlerScorllUp} onClickFeed={handleItemClick}/>
      <TikTokPannel feedItem={feedItem} onClickFeed={handleItemClick}/>
  </>)
}

export default TikTokPage;
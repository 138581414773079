import './ScrollableList.css'
import { useState, useEffect, useRef } from 'react';

const ScrollableList = (props) => {
  const { onScrollUp, children } = props;
  const [ loading, setLoading ] = useState(false);
  const prevScrollTop = useRef(0);
  const scrollRef = useRef(null);

  const handleScroll = (e) =>{
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if(!loading && scrollTop > prevScrollTop.current && scrollTop + clientHeight >= scrollHeight - 100){
      setLoading(true);
      setTimeout(async ()=>{
        await onScrollUp();
        scrollRef.current.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
        setLoading(false);
      }, 1000);
    }
    prevScrollTop.current = scrollTop;
  }
  
  return (
    <>
      <div className='scrollable-list' onScroll={handleScroll} ref={scrollRef}>
        { children }
        <div className={'scrollable-loading-item'}> Loading </div>
      </div>
    </>
  )
}

export default ScrollableList;
import axios from "axios";

const HOST = 'https://feed.vexmico.net';
// const HOST = 'http://127.0.0.1:3005';
export const Timeline2 = async(timestamp) =>{
  const items = [
    {
      "feed_id": "ytb:VcUO6OqjA_o",
      "feed_type": 1,
      "feed_source": "YOUTUBE",
      "feed_source_id": "7317829598446619911",
      "feed_source_link": "https://www.youtube.com/watch?v=VcUO6OqjA_o",
      "title": "Ledger Phishing Attack Causes Panic",
      "content": "The Ledger phishing attack has now been resolved.\n\nMore content is waiting for you on Bankless.com 🚀 \nhttps://bankless.cc/YouTubeInfo",
      "poster": "https://i.ytimg.com/vi/VcUO6OqjA_o/maxres2.jpg?sqp=-oaymwEoCIAKENAF8quKqQMcGADwAQH4AbYIgAKAD4oCDAgAEAEYZSBMKEMwDw==&rs=AOn4CLBTP97FS-rG6yg2ApYw81aFgFPh8A",
      "channel_uid": "ytb:UCAl9Ld79qaZxp9JzEOwd3aA",
      "channel_source_id": "cherryapricotss",
      "channel_source_name": "Bankless",
      "channel_source_icon": "https://yt3.googleusercontent.com/Kg9PztYJPSEBxR4XivFR5hpePJ1AO6Qwcs_4vARyxiiXWIfQNbkKCc5VilY4DVb1NzqICCE7=s900-c-k-c0x00ffffff-no-rj",
      "meta": {
          "labels": [],
          "descriptions": [
              "The Ledger phishing attack has now been resolved.",
              "",
              "More content is waiting for you on Bankless.com 🚀 ",
              "https://bankless.cc/YouTubeInfo"
          ]
      },
      "online": 1,
      "published_ts": "1702848602000",
      "create_ts": "1703741053511",
      "update_ts": "1703741053511",
      "media": "https://www.youtube.com/embed/VcUO6OqjA_o"
  },
  ]
  return {status:200, code:200, items, msg:'ok'}
}

export const GetFeed = async(feedId) =>{
  try {
    const params = {feed_id:feedId}
    const response = await axios.get(`${HOST}/feed/v1/detail`,{params});
    const { code, data, msg } = response.data;
    const feed = data.feed;
    return {status:200, code, feed, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}
export const Channels = async(source) => {
  try {
    const params = {source}
    const response = await axios.get(`${HOST}/feed/v1/channels`,{params});
    const { code, data, msg } = response.data;
    const items = data.items;
    return {status:200, code, items, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}

export const Timeline = async (source, timestamp) =>{
  try {
    const params = {timestamp,source}
    const response = await axios.get(`${HOST}/feed/v1/timeline`,{params});
    const { code, data, msg } = response.data;
    const items = data.items;
    return {status:200, code, items, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}

export const Following = async(channelUID, timestamp) =>{
  try {
    const params = { channel_uid:channelUID, timestamp}
    const response = await axios.get(`${HOST}/feed/v1/following`,{params});
    const { code, data, msg } = response.data;
    const items = data.items;
    return {status:200, code, items, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}

export const SubChannel = async (sourceType, sourceUid, sourceId) =>{
  try {
    const payload = { data:{
      channel_source_type:sourceType,
      channel_source_uid:sourceUid,
      channel_source_id:sourceId
    } }
    const response = await axios.post(`${HOST}/feed/v1/channel`, payload);
    const { code, msg } = response.data;
    return {status:200, code, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}

export const BanFeed = async (feedId) =>{
  try {
    const payload = { data:{
      feed_id:feedId,
    } }
    const response = await axios.post(`${HOST}/feed/v1/ban`, payload);
    const { code, msg } = response.data;
    return {status:200, code, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}

export const SetHashTag = async (feedId, hashtag) =>{
  try {
    const payload = { data:{
      feed_id:feedId,
      hashtag:hashtag,
    } }
    const response = await axios.post(`${HOST}/feed/v1/feed/hashtag`, payload);
    const { code, msg } = response.data;
    return {status:200, code, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}


export const TimelineV1 = async (timestamp) =>{
  try {
    const params = {timestamp}
    const response = await axios.get(`${HOST}/feed/v1/timeline`,{params});
    const { code, data, msg } = response.data;
    const items = data.items;
    return {status:200, code, items, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}



export const RecoverChannel = async(channelUID) => {
  try {
    const payload = { data:{channel_uid:channelUID} }
    const response = await axios.post(`${HOST}/feed/v1/channel/recover`, payload);
    const { code, msg } = response.data;
    return {status:200, code, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}

export const AbandonChannel = async(channelUID) => {
  try {
    const payload = { data:{channel_uid:channelUID} }
    const response = await axios.post(`${HOST}/feed/v1/channel/abandon`, payload);
    const { code, msg } = response.data;
    return {status:200, code, msg}
  } catch (error) {
    if(axios.isAxiosError(error) && error.response){
      return {status:error.response.status, code:error.response.status, msg:'network issue' }
    }
    return {status:502, code:502, msg:'network issue'};
  }
}
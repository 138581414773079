import './MVideoPlayer.css';
import React, { useEffect, useState, useRef } from 'react';

const MVideoPlayer = ({mediaLink, onVideoRef, rStart}) => {
  const videoRef = useRef();
  const startTimeRef = useRef(rStart);
  const [showControls, setShowControls] = useState(false);

  useEffect(()=>{
    startTimeRef.current = rStart?rStart:0;
  },[rStart])
  const handleVideoClick = () =>{
    setShowControls((prevShowControls) => !prevShowControls);
    videoRef.current?.play()
  }

  useEffect(() => {
    const handleVideoEnded = () => {
      videoRef.current.currentTime = startTimeRef.current;
      videoRef.current?.play();
    };

    videoRef.current?.addEventListener('ended', handleVideoEnded);
    videoRef.current?.load();
    return () => {
      videoRef.current?.removeEventListener('ended', handleVideoEnded);
    };
  }, [mediaLink]);

  useEffect(() => {
    if (onVideoRef && typeof onVideoRef === 'function') {
      onVideoRef(videoRef);
    }
  }, [onVideoRef, videoRef]);


  return (
    <div className='mvideo-player-container'>
      <video ref={videoRef} className='mvideo-player' controls={showControls} onClick={handleVideoClick} autoPlay playsInline webkit-playsinline="true" preload={"auto"}>
        <source src={ mediaLink } type="video/mp4"/>
      </video>
    </div>
  )
};
export default MVideoPlayer;